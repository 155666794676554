import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore'
import { collection, doc, getDoc } from 'firebase/firestore';
import { auth, provider, signInWithPopup, signOut } from '../firebase';
import { FcGoogle } from "react-icons/fc";
import db from '../firebase';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormControl, InputGroup, Row } from 'react-bootstrap'

export default class StartPage extends Component {

    constructor(props) {
        super(props)
        this.state = LoadProduction.init;
    }

    async nextStep() {
        this.props.nextFrame(false)
    }


    handleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log(user)
            this.setState({ user });
        } catch (error) {
            console.error('Error durante el inicio de sesión:', error);
        }
    };

    handleLogout = async () => {
        try {
            await signOut(auth);
            this.setState({ user: null });
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };


    render() {
        return (
            <>
                {!this.state.user ? (
                    <Card className='w-85'>
                        <CardHeader>
                            <CardTitle>{this.state.txtTitle[0]}</CardTitle>
                        </CardHeader>
                        <Row>
                            <Col>
                                <CardBody>
                                    <p>Con unos sensillos pasos podras crear tu app</p>
                                </CardBody>
                                <CardFooter>
                                    <Button onClick={() => { this.nextStep() }}>{this.state.btnTxtTitle[1]}</Button>
                                    <br />
                                    <p className='f-s-1 c-pointer m-t-1' onClick={() => { window.location = '/' }}>Volver</p>
                                </CardFooter>
                            </Col>
                            <Col>
                                <div className="z-modal text-center">
                                    <h1 className="color-font mb-4">Crear app con tu cuenta de Google</h1>
                                    <Button
                                        style={{
                                            backgroundColor: "#ffffff",
                                            border: "1px solid #dddddd",
                                            color: "#555555",

                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "10px",
                                            width: "fit-content",
                                            padding: "10px 20px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                        onClick={this.handleLogin}
                                    >
                                        <FcGoogle size={24} />
                                        Iniciar sesión con Google
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                    </Card>) : (
                    <>
                        <div>
                            <h1 className="color-font mb-4">Proximamente podras crear tu app mas facil ...</h1>
                            <footer><br />
                                <p className='f-s-1 c-pointer m-t-1' onClick={() => { window.location = '/' }}>Volver</p></footer>
                        </div>
                    </>

                )
                }
            </>
        )
    }
}
