import React, { Component } from 'react';
import { LoadProduction } from '../../store/MetaStore';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db from '../../firebase';
import { FaCirclePlus } from 'react-icons/fa6'
import { Modal, Button, FormControl, InputGroup, Card, CardBody, CardTitle, Carousel } from 'react-bootstrap';



export default class FrameLandingpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...LoadProduction.init,
      contentMap: [],
      contentList: [],
      modalCreateItem: false,
      pointSelectedType: '',
      newContent: {},
      pointSelectedContentId: null,
      isFooterVisible: false,
      idItem: '',
    }
  }

  cleanPathname() {
    const { pathname } = window.location;
    const decodedPathname = decodeURIComponent(pathname);
    return decodedPathname.startsWith('/admin/')
      ? decodedPathname.substring('/admin/'.length)
      : decodedPathname;
  }

  async componentDidMount() {
    const { pathname } = window.location;
    const cleanedPathname = this.cleanPathname()
    const license = await doc(db, '$:__sites-name', cleanedPathname)
    this.setState({ nameSite: cleanedPathname })
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      this.state = isLicenseAvalible.data()
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        }, () => { })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 1500);
    }
  }

  handleCreateContainer = () => {
    this.setState({ modalCreateItem: true, pointSelectedType: '', newContent: {} });
  };

  handleSaveContent = () => {
    const { pointSelectedType, newContent, contentMap } = this.state;

    if (!pointSelectedType || Object.keys(newContent).length === 0) {
      alert('Por favor completa el contenido antes de guardar.');
      return;
    }

    this.setState({
      contentMap: [...contentMap, { type: pointSelectedType, ...newContent }],
      modalCreateItem: false,
      newContent: {},
    }, () => { this.handleUpdateCong() });
  };

  handleContentClick = (index) => {
    const selectedContent = this.state.contentMap[index];
    this.setState({
      selectedContentIndex: index,
      isFooterVisible: true,
      newContent: selectedContent,
    });
  };

  handleModifyContent = (e) => {
    const updatedContent = { ...this.state.newContent, style: { ...this.state.newContent.style, color: e.target.value } };
    this.setState({
      newContent: updatedContent,
      contentMap: this.state.contentMap.map((content, index) =>
        index === this.state.selectedContentIndex ? updatedContent : content
      ),
    });
  };

  renderModalContent = () => {
    const { pointSelectedType, newContent } = this.state;
    console.log(this.state)
    switch (pointSelectedType) {
      case 'Encabezado':
        return (
          <InputGroup>
            <FormControl
              placeholder="Título"
              onChange={(e) =>
                this.setState({
                  newContent: { ...newContent, title: e.target.value },
                })
              }
            />
          </InputGroup>
        );
      case 'Texto':
        return (
          <InputGroup>
            <FormControl
              as="textarea"
              placeholder="Escribe el texto"
              onChange={(e) =>
                this.setState({
                  newContent: { ...newContent, text: e.target.value },
                })
              }
            />
          </InputGroup>
        );
      case 'Imagen':
      case 'Video':
        return (
          <InputGroup>
            <FormControl
              placeholder="URL del recurso"
              onChange={(e) =>
                this.setState({
                  newContent: { ...newContent, src: e.target.value },
                })
              }
            />
          </InputGroup>
        );
      default:
        return <p>Selecciona un tipo de contenido</p>;
    }
  };

  renderContent = () => {
    const { contentMap } = this.state;

    return contentMap.map((content, index) => {
      switch (content.type) {
        case 'Encabezado':
          return <>
            <div >
              <h2 key={index} onClick={() => { this.setState({ idItem: 'a' + index, isFooterVisible: true }); console.log(this.state) }} id={'a' + index} style={content.style}>{content.title}</h2>
              <br />
            </div>
          </>;
        case 'Texto':
          return <><p key={index} style={content.style}>{content.text}</p><br /></>;
        case 'Imagen':
          return <><img key={index} src={content.src} alt="Contenido" style={{ maxWidth: '100%' }} /><br /></>;
        case 'Video':
          return (
            <video key={index} controls style={{ maxWidth: '100%' }}>
              <source src={content.src} type="video/mp4" />
            </video>
          );
        case 'Carousel':
          return (
            <div key={index} style={{ maxWidth: '100%' }}>
              <Carousel>
                {content.items.map((item, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={item.src}
                      alt={`Slide ${i}`}
                      style={{ width: '100%' }}
                    />
                    {item.caption && (
                      <Carousel.Caption>
                        <h3>{item.caption.title}</h3>
                        <p>{item.caption.text}</p>
                      </Carousel.Caption>
                    )}
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          );
        case 'Navbar':
          return (
            <nav key={index} style={{ backgroundColor: content.style.backgroundColor || '#333', padding: '10px', color: '#fff' }}>
              <h4 style={{ margin: 0 }}>{content.navTitle}</h4>
              <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: '10px 0 0 0', gap: '15px' }}>
                {content.links.map((link, i) => (
                  <li key={i}>
                    <a href={link.href} style={{ color: '#fff', textDecoration: 'none' }}>
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          );

        case 'Grid':
          return (
            <div key={index} className="container">
              {content.rows.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                  {row.columns.map((col, colIndex) => (
                    <div key={colIndex} className={`col-${col.size || 12}`}>
                      {col.content}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          );
        default:
          return null;
      }
    });
  };

  async handleUpdateCong() {
    try {
      this.setState({
        modalAlert: true,
        txtMainAlert: 'Configuracion emparejada con el servidor',
        txtTitleAlert: 'Mi sistemita',
        alertState: 'success',
      }, async () => {
        this.setState({
          modalAlert: false,
          menuToActive: 'none'
        })
        await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state)
      })
    } catch (e) {
      this.setState({
        modalAlert: true,
        txtMainAlert: 'Error al emparejar con el servidor',
        txtTitleAlert: 'Mi sistemita',
        alertState: 'danger',
      })
    }
  }

  render() {
    const { cardBackground, newContent, modalCreateItem, pointSelectedType, contentList, isFooterVisible, pointSelectedContentId, selectedContentIndex } = this.state;

    return (
      <>
        <div style={this.state.choiseColorBackground} className='App-header'>
          <div className="btn-sty t-0" onClick={this.handleCreateContainer}>
            <FaCirclePlus />
          </div>
          {this.renderContent()}
          <Modal show={modalCreateItem} onHide={() => this.setState({ modalCreateItem: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Crear Contenido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-3">
                <FormControl
                  as="select"
                  value={pointSelectedType}
                  onChange={(e) => this.setState({ pointSelectedType: e.target.value })}
                >
                  <option value="">Selecciona un tipo</option>
                  <option value="Encabezado">Encabezado</option>
                  <option value="Texto">Texto</option>
                  <option value="Imagen">Imagen</option>
                  <option value="Video">Video</option>
                  <option value="Carousel">Carousel</option>
                  <option value="Navbar">Navbar</option>
                  <option value="Grid">Grid</option>
                </FormControl>
              </InputGroup>
              {/* Formulario dinámico según el tipo seleccionado */}
              {this.renderModalContent()}
              {pointSelectedType === 'Encabezado' || pointSelectedType === 'Texto' ? (
                <div>
                  <h5>Opciones de Estilo</h5>
                  <InputGroup className="mb-3">
                    <FormControl
                      type="color"
                      title="Color de fuente"
                      onChange={(e) =>
                        this.setState({
                          newContent: {
                            ...newContent,
                            style: { ...newContent.style, color: e.target.value },
                          },
                        })
                      }
                    />
                    <FormControl
                      type="number"
                      placeholder="Tamaño de fuente (px)"
                      onChange={(e) =>
                        this.setState({
                          newContent: {
                            ...newContent,
                            style: { ...newContent.style, fontSize: `${e.target.value}px` },
                          },
                        })
                      }
                    />
                  </InputGroup>
                  {/* Posición del contenido */}
                  <div className="mb-3">
                    <h6>Mover Posición</h6>
                    <InputGroup>
                      <FormControl
                        type="number"
                        placeholder="Top (px)"
                        onChange={(e) =>
                          this.setState({
                            newContent: {
                              ...newContent,
                              style: { ...newContent.style, top: `${e.target.value}px` },
                            },
                          })
                        }
                      />
                      <FormControl
                        type="number"
                        placeholder="Left (px)"
                        onChange={(e) =>
                          this.setState({
                            newContent: {
                              ...newContent,
                              style: { ...newContent.style, left: `${e.target.value}px` },
                            },
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  {/* Sombras y subrayado */}
                  <div className="mb-3">
                    <label>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          this.setState({
                            newContent: {
                              ...newContent,
                              style: {
                                ...newContent.style,
                                textShadow: e.target.checked
                                  ? '2px 2px 5px rgba(0,0,0,0.3)'
                                  : 'none',
                              },
                            },
                          })
                        }
                      />{' '}
                      Sombra de texto
                    </label>
                  </div>
                  <div className="mb-3">
                    <label>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          this.setState({
                            newContent: {
                              ...newContent,
                              style: {
                                ...newContent.style,
                                textDecoration: e.target.checked ? 'underline' : 'none',
                              },
                            },
                          })
                        }
                      />{' '}
                      Subrayado
                    </label>
                  </div>
                </div>
              ) : null}
              {pointSelectedType === 'Carousel' && (
                <div>
                  <h5>Crear Carousel</h5>
                  <div>
                    {newContent.items?.map((item, index) => (
                      <div key={index} className="mb-3">
                        <InputGroup className="mb-2">
                          <FormControl
                            type="text"
                            placeholder={`Imagen ${index + 1} URL`}
                            value={item.src || ''}
                            onChange={(e) => {
                              const updatedItems = [...newContent.items];
                              updatedItems[index] = { ...updatedItems[index], src: e.target.value };
                              this.setState({
                                newContent: { ...newContent, items: updatedItems },
                              });
                            }}
                          />
                          <Button
                            variant="danger"
                            onClick={() => {
                              const updatedItems = [...newContent.items];
                              updatedItems.splice(index, 1);
                              this.setState({
                                newContent: { ...newContent, items: updatedItems },
                              });
                            }}
                          >
                            -
                          </Button>
                        </InputGroup>

                        {/* Caption inputs */}
                        <InputGroup className="mb-2">
                          <FormControl
                            type="text"
                            placeholder="Título (opcional)"
                            value={item.caption?.title || ''}
                            onChange={(e) => {
                              const updatedItems = [...newContent.items];
                              updatedItems[index] = {
                                ...updatedItems[index],
                                caption: { ...updatedItems[index].caption, title: e.target.value },
                              };
                              this.setState({
                                newContent: { ...newContent, items: updatedItems },
                              });
                            }}
                          />
                        </InputGroup>
                        <InputGroup>
                          <FormControl
                            type="text"
                            placeholder="Texto (opcional)"
                            value={item.caption?.text || ''}
                            onChange={(e) => {
                              const updatedItems = [...newContent.items];
                              updatedItems[index] = {
                                ...updatedItems[index],
                                caption: { ...updatedItems[index].caption, text: e.target.value },
                              };
                              this.setState({
                                newContent: { ...newContent, items: updatedItems },
                              });
                            }}
                          />
                        </InputGroup>
                      </div>
                    ))}

                    {/* Botón para agregar nueva imagen */}
                    <Button
                      variant="success"
                      onClick={() =>
                        this.setState({
                          newContent: {
                            ...newContent,
                            items: [...(newContent.items || []), { src: '', caption: {} }],
                          },
                        })
                      }
                    >
                      + Agregar Imagen
                    </Button>
                  </div>
                </div>
              )}
              {pointSelectedType === 'Navbar' && (
                <div>
                  <h5>Crear Navbar</h5>
                  <InputGroup className="mb-3">
                    <FormControl
                      type="text"
                      placeholder="Título del Navbar"
                      value={newContent.navTitle || ''}
                      onChange={(e) =>
                        this.setState({
                          newContent: { ...newContent, navTitle: e.target.value },
                        })
                      }
                    />
                  </InputGroup>
                  <div>
                    <h6>Vínculos del Navbar</h6>
                    {newContent.links?.map((link, index) => (
                      <InputGroup key={index} className="mb-3">
                        <FormControl
                          type="text"
                          placeholder={`Texto del vínculo ${index + 1}`}
                          value={link.text || ''}
                          onChange={(e) => {
                            const updatedLinks = [...newContent.links];
                            updatedLinks[index] = { ...updatedLinks[index], text: e.target.value };
                            this.setState({
                              newContent: { ...newContent, links: updatedLinks },
                            });
                          }}
                        />
                        <FormControl
                          type="text"
                          placeholder={`URL del vínculo ${index + 1}`}
                          value={link.url || ''}
                          onChange={(e) => {
                            const updatedLinks = [...newContent.links];
                            updatedLinks[index] = { ...updatedLinks[index], url: e.target.value };
                            this.setState({
                              newContent: { ...newContent, links: updatedLinks },
                            });
                          }}
                        />
                        <Button
                          variant="danger"
                          onClick={() => {
                            const updatedLinks = [...newContent.links];
                            updatedLinks.splice(index, 1);
                            this.setState({
                              newContent: { ...newContent, links: updatedLinks },
                            });
                          }}
                        >
                          -
                        </Button>
                      </InputGroup>
                    ))}
                    <Button
                      variant="success"
                      onClick={() =>
                        this.setState({
                          newContent: {
                            ...newContent,
                            links: [...(newContent.links || []), { text: '', url: '' }],
                          },
                        })
                      }
                    >
                      + Agregar Vínculo
                    </Button>
                  </div>
                </div>
              )}
              {pointSelectedType === 'Grid' && (
                <div>
                  <h5>Crear Grid (Filas y Columnas)</h5>
                  <div>
                    {newContent.rows?.map((row, rowIndex) => (
                      <div key={rowIndex} className="mb-3">
                        <h6>Fila {rowIndex + 1}</h6>
                        {/* Mostrar las columnas de la fila */}
                        {row.columns.map((col, colIndex) => (
                          <InputGroup key={colIndex} className="mb-2">
                            <FormControl
                              type="text"
                              placeholder={`Contenido Columna ${colIndex + 1}`}
                              value={col.content || ''}
                              onChange={(e) => {
                                const updatedRows = [...newContent.rows];
                                updatedRows[rowIndex].columns[colIndex].content = e.target.value;
                                this.setState({
                                  newContent: { ...newContent, rows: updatedRows },
                                });
                              }}
                            />
                            <FormControl
                              type="number"
                              placeholder="Tamaño de Columna (1-12)"
                              value={col.size || ''}
                              onChange={(e) => {
                                const updatedRows = [...newContent.rows];
                                updatedRows[rowIndex].columns[colIndex].size = e.target.value;
                                this.setState({
                                  newContent: { ...newContent, rows: updatedRows },
                                });
                              }}
                            />
                            <Button
                              variant="danger"
                              onClick={() => {
                                const updatedRows = [...newContent.rows];
                                updatedRows[rowIndex].columns.splice(colIndex, 1);
                                this.setState({
                                  newContent: { ...newContent, rows: updatedRows },
                                });
                              }}
                            >
                              -
                            </Button>
                          </InputGroup>
                        ))}

                        {/* Botón para agregar columna */}
                        <Button
                          variant="success"
                          onClick={() => {
                            const updatedRows = [...newContent.rows];
                            updatedRows[rowIndex].columns.push({ size: 12, content: '' });
                            this.setState({
                              newContent: { ...newContent, rows: updatedRows },
                            });
                          }}
                        >
                          + Agregar Columna
                        </Button>
                      </div>
                    ))}

                    {/* Botón para agregar fila */}
                    <Button
                      variant="primary"
                      onClick={() =>
                        this.setState({
                          newContent: {
                            ...newContent,
                            rows: [...(newContent.rows || []), { columns: [{ size: 12, content: '' }] }],
                          },
                        })
                      }
                    >
                      + Agregar Fila
                    </Button>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ modalCreateItem: false })}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={this.handleSaveContent}>
                Guardar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          {/* Lista de componentes */}
          <div>
            {contentList.map((content, index) => (
              <div key={index} style={content.style}>
                {content.text}
                <button
                  onClick={() =>
                    this.setState({ pointSelectedContentId: index, isFooterVisible: true })
                  }
                >
                  ^
                </button>
              </div>
            ))}
          </div>
          {/* Footer */}
          {isFooterVisible && (
            <footer
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                background: '#fff',
                padding: '1rem',
                boxShadow: '0 -2px 5px rgba(0,0,0,0.2)',
              }}
            >
              {selectedContentIndex !== null ? (
                <div>
                  <h5>Editando Contenido {selectedContentIndex + 1}</h5>
                  <div>
                    <label>Color de fuente:</label>
                    <input
                      type="color"
                      value={newContent.style?.color || '#000000'}
                      onChange={this.handleModifyContent}
                    />
                  </div>
                  {/* Aquí puedes agregar más controles para otros parámetros de estilo o contenido */}
                  <button onClick={() => this.setState({ isFooterVisible: false })}>Cerrar</button>
                </div>
              ) : (
                <div>
                  <h5>Selecciona un elemento para editar</h5>
                  <button onClick={() => this.setState({ isFooterVisible: false })}>Cerrar</button>
                </div>
              )}
            </footer>
          )}
        </div>
      </>
    );
  }
}
